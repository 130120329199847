import React from 'react';

type Props = {
    className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
    <svg className={className} width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.5 22.5C28.7156 22.5 33.75 17.4656 33.75 11.25C33.75 5.03437 28.7156 0 22.5 0C16.2844 0 11.25 5.03437 11.25 11.25C11.25 17.4656 16.2844 22.5 22.5 22.5ZM22.5 28.125C14.9906 28.125 0 31.8937 0 39.375V45H45V39.375C45 31.8937 30.0094 28.125 22.5 28.125Z"
            fill="#A2A2A2"
        />
    </svg>
);

export default Icon;
