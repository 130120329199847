import React from 'react';

type Props = {
    className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
    <svg className={className} width="62" height="45" viewBox="0 0 62 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.0718 25.6777C47.9249 28.2934 50.6249 31.8371 50.6249 36.5621V44.9996H61.8749V36.5621C61.8749 30.4309 51.8343 26.8027 44.0718 25.6777Z"
            fill="#A1A1A1"
        />
        <path
            d="M22.4995 22.5C28.7127 22.5 33.7495 17.4632 33.7495 11.25C33.7495 5.0368 28.7127 0 22.4995 0C16.2863 0 11.2495 5.0368 11.2495 11.25C11.2495 17.4632 16.2863 22.5 22.4995 22.5Z"
            fill="#A1A1A1"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.3749 22.5C45.5905 22.5 50.6249 17.4656 50.6249 11.25C50.6249 5.03437 45.5905 0 39.3749 0C38.053 0 36.8155 0.281249 35.6343 0.674999C37.9687 3.57187 39.3749 7.25625 39.3749 11.25C39.3749 15.2437 37.9687 18.9281 35.6343 21.825C36.8155 22.2188 38.053 22.5 39.3749 22.5Z"
            fill="#A1A1A1"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.5 25.3125C14.9906 25.3125 0 29.0812 0 36.5625V45H45V36.5625C45 29.0812 30.0094 25.3125 22.5 25.3125Z"
            fill="#A1A1A1"
        />
    </svg>
);

export default Icon;
